<template>
  <div class="div-padding" id="contactus">
    <b-container fluid class="pt-5 pb-lg-5 bg-purple-layer">
      <b-row class="pt-5 px-lg-5 pb-lg-5 mb-lg-5">
        <b-col lg="5" class="pt-5 mt-lg-5">
          <div class="text-1">
            {{ $t("website.contactus.title") }}
          </div>
          <div
            class="text-2 pt-4"
            v-html="$t('website.contactus.subtitle')"
          ></div>
        </b-col>
        <b-col lg="7" class="pt-5 mt-lg-2 pb-5">
          <form
            accept-charset="UTF-8"
            v-on:submit.prevent="onSubmit()"
            method="POST"
            enctype="multipart/form-data"
            id="ajaxForm"
          >
            <div class="form-box">
              <b-row class="loading" :class="{ loadingDiv: loading }"></b-row>
              <b-row class="mb-3">
                <b-col sm="4" class="pt-2">
                  <label for="fullname">{{
                    $t("website.contactus.field1")
                  }}</label
                  ><span style="color: #2d23a8">*</span>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    v-model="fullname"
                    type="text"
                    :placeholder="$t('website.contactus.field1')"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="4" class="pt-2">
                  <label for="workemail">{{
                    $t("website.contactus.field2")
                  }}</label
                  ><span style="color: #2d23a8">*</span>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    v-model="workemail"
                    type="email"
                    :placeholder="$t('website.contactus.field2')"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="4" class="pt-2">
                  <label for="phonenumber">{{
                    $t("website.contactus.field3")
                  }}</label
                  ><span style="color: #2d23a8">*</span>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    v-model="phonenumber"
                    type="text"
                    :placeholder="$t('website.contactus.field3')"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="4" class="pt-2">
                  <label for="companyname">{{
                    $t("website.contactus.field4")
                  }}</label
                  ><span style="color: #2d23a8">*</span>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    v-model="companyname"
                    type="text"
                    :placeholder="$t('website.contactus.field4')"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col sm="4" class="pt-2"
                  ><label for="companywebsite">{{
                    $t("website.contactus.field5")
                  }}</label
                  ><span style="color: #2d23a8">*</span>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    v-model="companywebsite"
                    type="text"
                    placeholder="Company Website"
                    required
                    pattern="^(https?://)?([a-zA-Z0-9]([a-zA-ZäöüÄÖÜ0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col sm="4" class=""
                  ><label for=""
                    >What is your monthly transaction volume?*</label
                  >
                </b-col>
                <b-col sm="8">
                  <b-form-select
                    v-model="transactionoption"
                    :options="transactionoptions"
                    required
                  >
                  </b-form-select>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col sm="4" class="pt-2">
                  <label for="helpoption">{{
                    $t("website.contactus.field6")
                  }}</label
                  ><span style="color: #2d23a8">*</span>
                </b-col>
                <b-col sm="8">
                  <b-form-select
                    v-model="helpoption"
                    :options="helpoptions"
                    required
                  >
                  </b-form-select>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col sm="4" class="pt-2">
                  <label for="extra">{{
                    $t("website.contactus.field7")
                  }}</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    v-model="extra"
                    type="text"
                    :placeholder="$t('website.contactus.field7')"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col
                  sm="4"
                  class="pt-2"
                  v-html="$t('website.contactus.field8')"
                ></b-col>
                <b-col sm="8">
                  <b-form-select
                    v-model="recognitionoption"
                    :options="recognitionoptions"
                    required
                  ></b-form-select>
                </b-col>
              </b-row>
              <!-- Hidden UTM Fields -->
              <!-- <input
                type="hidden"
                name="utm_source"
                v-model="this.$route.query.utm_source"
              />
              <input
                type="hidden"
                name="utm_medium"
                v-model="this.$route.query.utm_medium"
              />
              <input
                type="hidden"
                name="utm_campaign"
                v-model="this.$route.query.utm_campaign"
              />
              <input
                type="hidden"
                name="utm_content"
                v-model="this.utmContent"
              />
              <input
                type="hidden"
                name="utm_term"
                v-model="this.$route.query.utm_term"
              />
              <input
                type="hidden"
                name="utm_id"
                v-model="this.$route.query.utm_id"
              /> -->

              <b-row class="mb-3">
                <b-col sm="4" class="pt-2"></b-col>
                <b-col sm="8">
                  <b-form-checkbox
                    id="agreebox"
                    v-model="agreebox"
                    name="agreebox"
                    value="accepted"
                    unchecked-value="not_accepted"
                    style="font-size: 14px"
                    required
                  >
                    {{ $t("website.contactus.terms") }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="4" class="pt-2"></b-col>
                <b-col sm="8">
                  <b-modal v-model="modalSuccessbox">
                    <b-icon icon="check-circle-fill" variant="success"></b-icon
                    >&nbsp; {{ $t("website.contactus.text1") }}
                  </b-modal>
                  <b-modal v-model="modalFailbox">
                    <b-icon icon="x-circle-fill" variant="danger"></b-icon
                    >&nbsp; {{ $t("website.contactus.text2") }}</b-modal
                  >
                  <b-modal v-model="modalAgreebox"
                    >{{ $t("website.contactus.text3") }}.</b-modal
                  >
                  <!-- <button @click="recaptcha">Execute recaptcha</button> -->
                  <div style="text-align: center">
                    <b-button
                      pill
                      type="submit"
                      class="btn-tekkis text-3 mt-3 center-align mb-5 mr-auto ml-auto"
                      @click="executeRecaptcha"
                      id="submitbtn"
                      >{{ $t("website.contactus.msgbtn") }}
                      <img
                        class="img-fit-div"
                        style="width: 15px"
                        src="../assets/img/sendemail.png"
                        alt=""
                    /></b-button>
                  </div>

                  <recaptcha ref="recaptcha" @verify="submit"></recaptcha>
                </b-col>
              </b-row>
            </div>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
$large: 992px;

.div-padding {
  padding: 0% 0%;
}

.text-1 {
  font-family: Jost;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 69px;
  color: #fefefe;
}

.text-2 {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #fefefe;
}

.text-3 {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #fefefe;
}

.form-box {
  background: #fefefe;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5%;
}

.bg-purple-layer {
  background-image: url("../assets/img/contactus-bg.webp");
  /* Full height */
  // height: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  // margin-left: -500px !important;
}

.loadingDiv {
  display: none !important;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 15px solid rgb(243, 55, 112);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
import axios from "axios";
import Recaptcha from "../components/Recaptcha.vue";
import mixpanel from "mixpanel-browser";

// import Vue from "vue";
// import { VueReCaptcha } from "vue-recaptcha-v3";

// Vue.use(VueReCaptcha, { siteKey: "6LcLCsgdAAAAABqq2tJ3wLrlPaO_w5f-gr6aFQcA" });

export default {
  components: {
    Recaptcha,
  },
  data() {
    return {
      companylocationoptions: [
        { value: null, text: "Company Location" },
        { value: "Malaysia", text: "Malaysia" },
        { value: "Afganistan", text: "Afghanistan" },
        { value: "Albania", text: "Albania" },
        { value: "Algeria", text: "Algeria" },
        { value: "American Samoa", text: "American Samoa" },
        { value: "Andorra", text: "Andorra" },
        { value: "Angola", text: "Angola" },
        { value: "Anguilla", text: "Anguilla" },
        { value: "Antigua & Barbuda", text: "Antigua & Barbuda" },
        { value: "Argentina", text: "Argentina" },
        { value: "Armenia", text: "Armenia" },
        { value: "Aruba", text: "Aruba" },
        { value: "Australia", text: "Australia" },
        { value: "Austria", text: "Austria" },
        { value: "Azerbaijan", text: "Azerbaijan" },
        { value: "Bahamas", text: "Bahamas" },
        { value: "Bahrain", text: "Bahrain" },
        { value: "Bangladesh", text: "Bangladesh" },
        { value: "Barbados", text: "Barbados" },
        { value: "Belarus", text: "Belarus" },
        { value: "Belgium", text: "Belgium" },
        { value: "Belize", text: "Belize" },
        { value: "Benin", text: "Benin" },
        { value: "Bermuda", text: "Bermuda" },
        { value: "Bhutan", text: "Bhutan" },
        { value: "Bolivia", text: "Bolivia" },
        { value: "Bonaire", text: "Bonaire" },
        { value: "Bosnia & Herzegovina", text: "Bosnia & Herzegovina" },
        { value: "Botswana", text: "Botswana" },
        { value: "Brazil", text: "Brazil" },
        { value: "British Indian Ocean Ter", text: "British Indian Ocean Ter" },
        { value: "Brunei", text: "Brunei" },
        { value: "Bulgaria", text: "Bulgaria" },
        { value: "Burkina Faso", text: "Burkina Faso" },
        { value: "Burundi", text: "Burundi" },
        { value: "Cambodia", text: "Cambodia" },
        { value: "Cameroon", text: "Cameroon" },
        { value: "Canada", text: "Canada" },
        { value: "Canary Islands", text: "Canary Islands" },
        { value: "Cape Verde", text: "Cape Verde" },
        { value: "Cayman Islands", text: "Cayman Islands" },
        { value: "Central African Republic", text: "Central African Republic" },
        { value: "Chad", text: "Chad" },
        { value: "Channel Islands", text: "Channel Islands" },
        { value: "Chile", text: "Chile" },
        { value: "China", text: "China" },
        { value: "Christmas Island", text: "Christmas Island" },
        { value: "Cocos Island", text: "Cocos Island" },
        { value: "Colombia", text: "Colombia" },
        { value: "Comoros", text: "Comoros" },
        { value: "Congo", text: "Congo" },
        { value: "Cook Islands", text: "Cook Islands" },
        { value: "Costa Rica", text: "Costa Rica" },
        { value: "Cote DIvoire", text: "Cote DIvoire" },
        { value: "Croatia", text: "Croatia" },
        { value: "Cuba", text: "Cuba" },
        { value: "Curaco", text: "Curacao" },
        { value: "Cyprus", text: "Cyprus" },
        { value: "Czech Republic", text: "Czech Republic" },
        { value: "Denmark", text: "Denmark" },
        { value: "Djibouti", text: "Djibouti" },
        { value: "Dominica", text: "Dominica" },
        { value: "Dominican Republic", text: "Dominican Republic" },
        { value: "East Timor", text: "East Timor" },
        { value: "Ecuador", text: "Ecuador" },
        { value: "Egypt", text: "Egypt" },
        { value: "El Salvador", text: "El Salvador" },
        { value: "Equatorial Guinea", text: "Equatorial Guinea" },
        { value: "Eritrea", text: "Eritrea" },
        { value: "Estonia", text: "Estonia" },
        { value: "Ethiopia", text: "Ethiopia" },
        { value: "Falkland Islands", text: "Falkland Islands" },
        { value: "Faroe Islands", text: "Faroe Islands" },
        { value: "Fiji", text: "Fiji" },
        { value: "Finland", text: "Finland" },
        { value: "France", text: "France" },
        { value: "French Guiana", text: "French Guiana" },
        { value: "French Polynesia", text: "French Polynesia" },
        { value: "French Southern Ter", text: "French Southern Ter" },
        { value: "Gabon", text: "Gabon" },
        { value: "Gambia", text: "Gambia" },
        { value: "Georgia", text: "Georgia" },
        { value: "Germany", text: "Germany" },
        { value: "Ghana", text: "Ghana" },
        { value: "Gibraltar", text: "Gibraltar" },
        { value: "Great Britain", text: "Great Britain" },
        { value: "Greece", text: "Greece" },
        { value: "Greenland", text: "Greenland" },
        { value: "Grenada", text: "Grenada" },
        { value: "Guadeloupe", text: "Guadeloupe" },
        { value: "Guam", text: "Guam" },
        { value: "Guatemala", text: "Guatemala" },
        { value: "Guinea", text: "Guinea" },
        { value: "Guyana", text: "Guyana" },
        { value: "Haiti", text: "Haiti" },
        { value: "Hawaii", text: "Hawaii" },
        { value: "Honduras", text: "Honduras" },
        { value: "Hong Kong", text: "Hong Kong" },
        { value: "Hungary", text: "Hungary" },
        { value: "Iceland", text: "Iceland" },
        { value: "Indonesia", text: "Indonesia" },
        { value: "India", text: "India" },
        { value: "Iran", text: "Iran" },
        { value: "Iraq", text: "Iraq" },
        { value: "Ireland", text: "Ireland" },
        { value: "Isle of Man", text: "Isle of Man" },
        { value: "Israel", text: "Israel" },
        { value: "Italy", text: "Italy" },
        { value: "Jamaica", text: "Jamaica" },
        { value: "Japan", text: "Japan" },
        { value: "Jordan", text: "Jordan" },
        { value: "Kazakhstan", text: "Kazakhstan" },
        { value: "Kenya", text: "Kenya" },
        { value: "Kiribati", text: "Kiribati" },
        { value: "Korea North", text: "Korea North" },
        { value: "Korea Sout", text: "Korea South" },
        { value: "Kuwait", text: "Kuwait" },
        { value: "Kyrgyzstan", text: "Kyrgyzstan" },
        { value: "Laos", text: "Laos" },
        { value: "Latvia", text: "Latvia" },
        { value: "Lebanon", text: "Lebanon" },
        { value: "Lesotho", text: "Lesotho" },
        { value: "Liberia", text: "Liberia" },
        { value: "Libya", text: "Libya" },
        { value: "Liechtenstein", text: "Liechtenstein" },
        { value: "Lithuania", text: "Lithuania" },
        { value: "Luxembourg", text: "Luxembourg" },
        { value: "Macau", text: "Macau" },
        { value: "Macedonia", text: "Macedonia" },
        { value: "Madagascar", text: "Madagascar" },

        { value: "Malawi", text: "Malawi" },
        { value: "Maldives", text: "Maldives" },
        { value: "Mali", text: "Mali" },
        { value: "Malta", text: "Malta" },
        { value: "Marshall Islands", text: "Marshall Islands" },
        { value: "Martinique", text: "Martinique" },
        { value: "Mauritania", text: "Mauritania" },
        { value: "Mauritius", text: "Mauritius" },
        { value: "Mayotte", text: "Mayotte" },
        { value: "Mexico", text: "Mexico" },
        { value: "Midway Islands", text: "Midway Islands" },
        { value: "Moldova", text: "Moldova" },
        { value: "Monaco", text: "Monaco" },
        { value: "Mongolia", text: "Mongolia" },
        { value: "Montserrat", text: "Montserrat" },
        { value: "Morocco", text: "Morocco" },
        { value: "Mozambique", text: "Mozambique" },
        { value: "Myanmar", text: "Myanmar" },
        { value: "Nambia", text: "Nambia" },
        { value: "Nauru", text: "Nauru" },
        { value: "Nepal", text: "Nepal" },
        { value: "Netherland Antilles", text: "Netherland Antilles" },
        { value: "Netherlands", text: "Netherlands (Holland, Europe)" },
        { value: "Nevis", text: "Nevis" },
        { value: "New Caledonia", text: "New Caledonia" },
        { value: "New Zealand", text: "New Zealand" },
        { value: "Nicaragua", text: "Nicaragua" },
        { value: "Niger", text: "Niger" },
        { value: "Nigeria", text: "Nigeria" },
        { value: "Niue", text: "Niue" },
        { value: "Norfolk Island", text: "Norfolk Island" },
        { value: "Norway", text: "Norway" },
        { value: "Oman", text: "Oman" },
        { value: "Pakistan", text: "Pakistan" },
        { value: "Palau Island", text: "Palau Island" },
        { value: "Palestine", text: "Palestine" },
        { value: "Panama", text: "Panama" },
        { value: "Papua New Guinea", text: "Papua New Guinea" },
        { value: "Paraguay", text: "Paraguay" },
        { value: "Peru", text: "Peru" },
        { value: "Phillipines", text: "Philippines" },
        { value: "Pitcairn Island", text: "Pitcairn Island" },
        { value: "Poland", text: "Poland" },
        { value: "Portugal", text: "Portugal" },
        { value: "Puerto Rico", text: "Puerto Rico" },
        { value: "Qatar", text: "Qatar" },
        { value: "Republic of Montenegro", text: "Republic of Montenegro" },
        { value: "Republic of Serbia", text: "Republic of Serbia" },
        { value: "Reunion", text: "Reunion" },
        { value: "Romania", text: "Romania" },
        { value: "Russia", text: "Russia" },
        { value: "Rwanda", text: "Rwanda" },
        { value: "St Barthelemy", text: "St Barthelemy" },
        { value: "St Eustatius", text: "St Eustatius" },
        { value: "St Helena", text: "St Helena" },
        { value: "St Kitts-Nevis", text: "St Kitts-Nevis" },
        { value: "St Lucia", text: "St Lucia" },
        { value: "St Maarten", text: "St Maarten" },
        { value: "St Pierre & Miquelon", text: "St Pierre & Miquelon" },
        { value: "St Vincent & Grenadines", text: "St Vincent & Grenadines" },
        { value: "Saipan", text: "Saipan" },
        { value: "Samoa", text: "Samoa" },
        { value: "Samoa American", text: "Samoa American" },
        { value: "San Marino", text: "San Marino" },
        { value: "Sao Tome & Principe", text: "Sao Tome & Principe" },
        { value: "Saudi Arabia", text: "Saudi Arabia" },
        { value: "Senegal", text: "Senegal" },
        { value: "Seychelles", text: "Seychelles" },
        { value: "Sierra Leone", text: "Sierra Leone" },
        { value: "Singapore", text: "Singapore" },
        { value: "Slovakia", text: "Slovakia" },
        { value: "Slovenia", text: "Slovenia" },
        { value: "Solomon Islands", text: "Solomon Islands" },
        { value: "Somalia", text: "Somalia" },
        { value: "South Africa", text: "South Africa" },
        { value: "Spain", text: "Spain" },
        { value: "Sri Lanka", text: "Sri Lanka" },
        { value: "Sudan", text: "Sudan" },
        { value: "Suriname", text: "Suriname" },
        { value: "Swaziland", text: "Swaziland" },
        { value: "Sweden", text: "Sweden" },
        { value: "Switzerland", text: "Switzerland" },
        { value: "Syria", text: "Syria" },
        { value: "Tahiti", text: "Tahiti" },
        { value: "Taiwan", text: "Taiwan" },
        { value: "Tajikistan", text: "Tajikistan" },
        { value: "Tanzania", text: "Tanzania" },
        { value: "Thailand", text: "Thailand" },
        { value: "Togo", text: "Togo" },
        { value: "Tokelau", text: "Tokelau" },
        { value: "Tonga", text: "Tonga" },
        { value: "Trinidad & Tobago", text: "Trinidad & Tobago" },
        { value: "Tunisia", text: "Tunisia" },
        { value: "Turkey", text: "Turkey" },
        { value: "Turkmenistan", text: "Turkmenistan" },
        { value: "Turks & Caicos Is", text: "Turks & Caicos Is" },
        { value: "Tuvalu", text: "Tuvalu" },
        { value: "Uganda", text: "Uganda" },
        { value: "United Kingdom", text: "United Kingdom" },
        { value: "Ukraine", text: "Ukraine" },
        { value: "United Arab Erimates", text: "United Arab Emirates" },
        { value: "United States of America", text: "United States of America" },
        { value: "Uraguay", text: "Uruguay" },
        { value: "Uzbekistan", text: "Uzbekistan" },
        { value: "Vanuatu", text: "Vanuatu" },
        { value: "Vatican City State", text: "Vatican City State" },
        { value: "Venezuela", text: "Venezuela" },
        { value: "Vietnam", text: "Vietnam" },
        { value: "Virgin Islands (Brit)", text: "Virgin Islands (Brit)" },
        { value: "Virgin Islands (USA)", text: "Virgin Islands (USA)" },
        { value: "Wake Island", text: "Wake Island" },
        { value: "Wallis & Futana Is", text: "Wallis & Futana Is" },
        { value: "Yemen", text: "Yemen" },
        { value: "Zaire", text: "Zaire" },
        { value: "Zambia", text: "Zambia" },
        { value: "Zimbabwe", text: "Zimbabwe" },
      ],
      helpoptions: [
        { value: null, text: "How can we help?" },
        {
          value: "I am interested in collecting payments online with t-Pay",
          text: "I am interested in collecting payments online with t-Pay",
        },
        {
          value:
            "I am interested in digitally verifying people with t-Verify (eKYC)",
          text: "I am interested in digitally verifying people with t-Verify (eKYC) ",
        },
        {
          value:
            "I am interested in collecting payment online with t-Pay and digitally verifying people with t-Verify",
          text: "I am interested in collecting payment online with t-Pay and digitally verifying people with t-Verify ",
        },
        {
          value:
            "I am a Technology Provider considering a Partnership with Tekkis for t-Pay (Online Payment)",
          text: "I am a Technology Provider considering a Partnership with Tekkis for t-Pay (Online Payment)",
        },
        {
          value:
            "I am a Technology Provider considering a Partnership with Tekkis for t-Verify (eKYC)",
          text: "I am a Technology Provider considering a Partnership with Tekkis for t-Verify (eKYC)",
        },
        {
          value:
            "I am a Technology Provider considering a Partnership with Tekkis for t-Pay(Online Payment) and t-Verify (eKYC)",
          text: "I am a Technology Provider considering a Partnership with Tekkis for t-Pay(Online Payment) and t-Verify (eKYC)",
        },
        { value: "Other Reason", text: "Other Reason" },
      ],
      solutionoptions: [
        { value: null, text: "What solution are you looking for?" },
        {
          value: "Online payment (t-Pay)",
          text: "Online payment (t-Pay)",
        },
        {
          value: "Digital identity verification (t-Verify)",
          text: "Digital identity verification (t-Verify)",
        },
      ],
      recognitionoptions: [
        { value: null, text: "How did you hear about us?" },
        {
          value: "Google",
          text: "Google",
        },
        {
          value: "Facebook",
          text: "Facebook",
        },
        {
          value: "News",
          text: "News",
        },
        { value: "tekkis customer referral", text: "tekkis customer referral" },
        { value: "Word of mouth", text: "Word of mouth" },
        {
          value: "LinkedIn",
          text: "LinkedIn",
        },

        {
          value: "Instagram",
          text: "Instagram",
        },
        {
          value: "Twitter",
          text: "Twitter",
        },
      ],
      transactionoptions: [
        { value: null, text: "What is your monthly transaction volume?" },
        {
          value: "Below 2,000",
          text: "Below 2,000",
        },
        {
          value: "2,000-10,000",
          text: "2,000-10,000",
        },
        {
          value: "10,000-50,000",
          text: "10,000-50,000",
        },
        { value: "Over 50,000", text: "Over 50,000" },
      ],
      message: "",
      loading: true,
      fullname: "",
      workemail: "",
      phonenumber: "",
      companyname: "",
      companywebsite: "",
      helpoption: "",
      solutionoption: "",
      transactionoption: "",
      extra: "",
      recognitionoption: "",
      utmSource: this.$route.query.utm_source,
      utmMedium: this.$route.query.utm_medium,
      utmCampaign: this.$route.query.utm_campaign,
      utmContent: this.$route.query.utm_content,
      utmID: this.$route.query.utm_id,
      utmTerm: this.$route.query.utm_term,
      agreebox: "",
      formSource: "Tekkis Website(auto-populated)",
      modalSuccessbox: false,
      modalFailbox: false,
      modalAgreebox: false,
      ipUser: "",
    };
  },
  methods: {
    submit(response) {
      return response;
    },
    // execute the recaptcha widget
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
    generateKey() {
      const length = 8;
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result;
    },
    onSubmit() {
      if (this.agreebox != "") {
        this.message = "";
        this.loading = false;
        var fontKey = this.generateKey();
        var backKey = this.generateKey();
        const signature = fontKey + this.ipUser + backKey;
        const formData = new FormData();
        formData.append("fullname", this.fullname);
        formData.append("workemail", this.workemail);
        formData.append("phonenumber", this.phonenumber);
        formData.append("companyname", this.companyname);
        formData.append("companywebsite", this.companywebsite);
        formData.append("helpoption", this.helpoption);
        formData.append("transactionoption", this.transactionoption);
        formData.append("extra", this.extra);
        formData.append("recognitionoption", this.recognitionoption);
        formData.append("utmSource", this.utmSource);
        formData.append("utmMedium", this.utmMedium);
        formData.append("utmCampaign", this.utmCampaign);
        formData.append("utmContent", this.utmContent);
        formData.append("utmTerm", this.utmTerm);
        formData.append("agreebox", this.agreebox);
        formData.append("formSource", this.formSource);
        formData.append("signature", signature);

        axios
          .post("https://emailapi.tekkis.my/Web/email/send", formData, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.loading = false;
              this.modalSuccessbox = true;
              this.fullname = "";
              this.workemail = "";
              this.phonenumber = "";
              this.companyname = "";
              this.companywebsite = "";
              this.helpoption = "";
              this.extra = "";
              this.recognitionoption = "";
              this.utmSource = "";
              this.utmMedium = "";
              this.utmCampaign = "";
              this.utmContent = "";
              this.utmTerm = "";
              this.utmID = "";
              this.agreebox = "";
              if (
                this.transactionoption == "10,000-50,000" ||
                this.transactionoption == "Over 50,000"
              ) {
                this.transactionoption = "";
                //this.$router.push({ path: "/thankyou" });
              } else {
                this.transactionoption = "";
                //this.$router.push({ path: "/solutions-tpay" });
              }
            } else {
              this.modalFailbox = true;
              this.loading = false;
            }
            document.getElementById("submitbtn").disabled = false;
          });
      } else {
        this.modalAgreebox = true;
      }
    },
    async checkIpUser() {
      try {
        const response = await axios.post("http://ip-api.com/json/");
        console.log(response);
        var dataIpUser = {
          ipAddress: response.data.query,
          countryCode: response.data.countryCode,
          latLang: response.data.lat + "," + response.data.lon,
        };

        this.ipUser = encodeURIComponent(btoa(JSON.stringify(dataIpUser)));
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js"
    );
    document.head.appendChild(recaptchaScript);
    mixpanel.track_pageview({ page: "Contact Us" });
    this.checkIpUser();
  },
};
</script>
