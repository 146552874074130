<template>
  <div class="contactus">
    <CompanyContactUs />
    <GetStarted />
  </div>
</template>

<script>
import CompanyContactUs from "../components/CompanyContactUs.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "contactus",
  components: {
    CompanyContactUs,
    GetStarted,
  },
  data() {
    return {
      title: "Tekkis - Contact Us",
      description:
        "Get in touch with us today! Ask us anything about online payments and digital identity verification. We offer custom solutions and pricing aside from the best customer experience in town! ",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Get in touch with us today! Ask us anything about online payments and digital identity verification. We offer custom solutions and pricing aside from the best customer experience in town! ";
    },
  },
};
</script>
